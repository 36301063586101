<template>
  <section>
    <!-- <v-btn @click="testingg()">TEST</v-btn> -->
    <v-btn @click="showError()">TEST</v-btn>
    <v-btn @click="printLog()">LOG</v-btn>

    <v-btn @click="runAllGrades()">RUN ALL GRADES</v-btn>
    <!-- <v-btn @click="allProgramPlans">all program from schools</v-btn> -->
    <!-- <v-btn @click="allProgramStudents">all students from schools</v-btn> -->
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="red"
    ></v-progress-linear>
    <v-checkbox
      v-model="analysisMode"
      :label="analysisMode ? 'Хичээл харах Mode' : 'Дүн бүртгэх Mode'"
    ></v-checkbox>
    <v-simple-table v-if="schools" class="bborder">
      <thead>
        <tr>
          <th class="pl-2">No</th>
          <th class="pl-2">Name</th>
          <th
            class="text-center"
            colspan="2"
            v-for="depIndex in [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]"
            :key="'body-' + depIndex"
          >
            <div>
              <div @click="allDepartment(depIndex)" style="cursor: pointer">
                {{ depIndex }}
              </div>
              <div class="d-flex flex-row" style="border-top: 1px solid #bbb">
                <v-col
                  @click="allDepartment(depIndex, 1)"
                  class="px-0"
                  style="cursor: pointer"
                  >Ү.1</v-col
                >
                <v-col
                  @click="allDepartment(depIndex, 2)"
                  class="px-0"
                  style="
                    border-left: 1px solid #bbb;
                    border-right: 1px solid #bbb;
                    cursor: pointer;
                  "
                  >Ү.2</v-col
                >
                <!-- <v-col
                  @click="allDepartment(depIndex, 3)"
                  class="px-0"
                  style="cursor: pointer"
                  >Ж.Э</v-col
                > -->
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(school, sindex) in schools" :key="'school-' + sindex">
          <td class="pl-2">{{ sindex + 1 }}</td>
          <td class="pl-2" style="width: 300px" @click="saveSchool(school)">
            {{ school.name }}
          </td>
          <td
            :data-title2="
              school.name + '-' + gradeIndex.name + '-' + gradeIndex.id
            "
            class="text-center pa-0"
            style="width: 34px"
            v-for="gradeIndex in depAndIds"
            :key="'body2-' + gradeIndex.name + '-' + gradeIndex.id"
            :style="
              school.departments &&
              school.departments[gradeIndex.name - 1].exists
                ? school.departments[gradeIndex.name - 1][
                    'savedGradesToEsis-' + gradeIndex.id
                  ]
                  ? school.departments[gradeIndex.name - 1][
                      'savedGradesToEsis-' + gradeIndex.id
                    ].successfulLessonCounter /
                      school.departments[gradeIndex.name - 1][
                        'savedGradesToEsis-' + gradeIndex.id
                      ].totalLessonCounter ==
                    1
                    ? 'background-color: #ccffc7;'
                    : school.departments[gradeIndex.name - 1][
                        'savedGradesToEsis-' + gradeIndex.id
                      ].successfulLessonCounter /
                        school.departments[gradeIndex.name - 1][
                          'savedGradesToEsis-' + gradeIndex.id
                        ].totalLessonCounter ==
                      0
                    ? 'background-color: white; cursor:pointer'
                    : 'background-color: #faff9f;'
                  : 'background-color: white; cursor:pointer'
                : 'background-color: #ffb6b6'
            "
            @click="
              saveDepartmentScore(
                school,
                school.departments[gradeIndex.name - 1],
                gradeIndex.id,
                gradeIndex
              )
            "
          >
            <v-progress-linear
              v-if="gradeIndex.loading && gradeIndex.loading[school.id] == true"
              indeterminate
              color="red"
            ></v-progress-linear>
            <small v-else>
              {{
                school.departments &&
                school.departments[gradeIndex.name - 1].exists
                  ? school.departments[gradeIndex.name - 1][
                      "savedGradesToEsis-" + gradeIndex.id
                    ]
                    ? getDataAsString(
                        school.departments[gradeIndex.name - 1][
                          "savedGradesToEsis-" + gradeIndex.id
                        ]
                      )
                    : "-"
                  : "x"
              }}
            </small>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog
      v-model="showProgramLessonsDialog"
      v-if="programLessons"
      width="1000"
    >
      <v-card class="pa-3">
        <div
          @click="print22(lesson)"
          v-for="(lesson, lindex) in programLessons"
          :key="'lesson2' + lindex"
        >
          {{ lindex + 1 }}. {{ lesson.courseInfo.COURSE_NAME }} -
          {{ lesson.program.fullName }} -
          <span
            v-html="
              lesson.halfYearId == 3
                ? lesson.finalGradeSavedToEsis
                  ? `<span class='green--text'>Saved</span>`
                  : `<span class='red--text'>Not Saved</span>`
                : lesson['unelgee-' + lesson.halfYearId + '-SavedToEsis']
                ? `<span class='green--text'>Saved</span>`
                : `<span class='red--text'>Not Saved</span>`
            "
          ></span>
        </div>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
const fb = require("@/firebaseConfig.js");
export default {
  data: () => ({
    analysisMode: false,
    schools: null,
    loading: false,
    depAndIds: [
      { name: 12, id: 1 },
      { name: 12, id: 2 },
      // { name: 12, id: 3 },
      { name: 11, id: 1 },
      { name: 11, id: 2 },
      // { name: 11, id: 3 },
      { name: 10, id: 1 },
      { name: 10, id: 2 },
      // { name: 10, id: 3 },
      { name: 9, id: 1 },
      { name: 9, id: 2 },
      // { name: 9, id: 3 },
      { name: 8, id: 1 },
      { name: 8, id: 2 },
      // { name: 8, id: 3 },
      { name: 7, id: 1 },
      { name: 7, id: 2 },
      // { name: 7, id: 3 },
      { name: 6, id: 1 },
      { name: 6, id: 2 },
      // { name: 6, id: 3 },
      { name: 5, id: 1 },
      { name: 5, id: 2 },
      // { name: 5, id: 3 },
      { name: 4, id: 1 },
      { name: 4, id: 2 },
      // { name: 4, id: 3 },
      { name: 3, id: 1 },
      { name: 3, id: 2 },
      // { name: 3, id: 3 },
      { name: 2, id: 1 },
      { name: 2, id: 2 },
      // { name: 2, id: 3 },
      { name: 1, id: 1 },
      { name: 1, id: 2 },
      // { name: 1, id: 3 },
    ],
    headerNames: [
      {
        text: "ID",
        align: "start",
        width: "1%",
        value: "index",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Бүлэг",
        value: "STUDENT_GROUP_NAME",
      },
    ],
    programLessons: null,
    showProgramLessonsDialog: false,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
  },
  created() {
    fb.db
      .collection("schools")
      .orderBy("name", "asc")
      .get()
      .then((docs) => {
        this.schools = [];
        docs.forEach(async (doc) => {
          let school = doc.data();
          school.ref = doc.ref;
          school.id = doc.id;
          school.departments = null;
          if (school._esis_schoolInfo) {
            await school.ref
              .collection("departments-" + school.currentYear)
              .where("deleted", "==", false)
              .orderBy("index", "desc")
              .onSnapshot((docs) => {
                school.departments = [
                  { index: 1 },
                  { index: 2 },
                  { index: 3 },
                  { index: 4 },
                  { index: 5 },
                  { index: 6 },
                  { index: 7 },
                  { index: 8 },
                  { index: 9 },
                  { index: 10 },
                  { index: 11 },
                  { index: 12 },
                ];
                docs.forEach(async (doc) => {
                  let department = doc.data();
                  department.ref = doc.ref;
                  department.id = doc.id;
                  department.exists = true;
                  await department.ref
                    .collection("programs")
                    .where("deleted", "==", false)
                    .onSnapshot((docs) => {
                      department["savedGradesToEsis-1"] = null;
                      department["savedGradesToEsis-2"] = null;
                      department["savedGradesToEsis-3"] = null;
                      department.programs = [];
                      docs.forEach((doc) => {
                        let prog = doc.data();
                        prog.ref = doc.ref;
                        prog.id = doc.id;
                        if (prog["savedGradesToEsis-1"]) {
                          if (!department["savedGradesToEsis-1"])
                            department["savedGradesToEsis-1"] =
                              prog["savedGradesToEsis-1"];
                          else {
                            department[
                              "savedGradesToEsis-1"
                            ].totalLessonCounter +=
                              prog["savedGradesToEsis-1"].totalLessonCounter;
                            department[
                              "savedGradesToEsis-1"
                            ].successfulLessonCounter +=
                              prog[
                                "savedGradesToEsis-1"
                              ].successfulLessonCounter;
                          }
                        }

                        if (prog["savedGradesToEsis-2"]) {
                          if (!department["savedGradesToEsis-2"])
                            department["savedGradesToEsis-2"] =
                              prog["savedGradesToEsis-2"];
                          else {
                            department[
                              "savedGradesToEsis-2"
                            ].totalLessonCounter +=
                              prog["savedGradesToEsis-2"].totalLessonCounter;
                            department[
                              "savedGradesToEsis-2"
                            ].successfulLessonCounter +=
                              prog[
                                "savedGradesToEsis-2"
                              ].successfulLessonCounter;
                          }
                        }

                        if (prog["savedGradesToEsis-3"]) {
                          if (!department["savedGradesToEsis-3"])
                            department["savedGradesToEsis-3"] =
                              prog["savedGradesToEsis-3"];
                          else {
                            department[
                              "savedGradesToEsis-3"
                            ].totalLessonCounter +=
                              prog["savedGradesToEsis-3"].totalLessonCounter;
                            department[
                              "savedGradesToEsis-3"
                            ].successfulLessonCounter +=
                              prog[
                                "savedGradesToEsis-3"
                              ].successfulLessonCounter;
                          }
                        }
                        department.programs.push(prog);
                        this.$forceUpdate();
                      });
                      if (
                        department["savedGradesToEsis-1"] &&
                        department["savedGradesToEsis-1"].totalLessonCounter ==
                          0
                      ) {
                        department[
                          "savedGradesToEsis-1"
                        ].successfulLessonCounter = 0;
                        department[
                          "savedGradesToEsis-1"
                        ].totalLessonCounter = 1;
                      }
                      if (
                        department["savedGradesToEsis-2"] &&
                        department["savedGradesToEsis-2"].totalLessonCounter ==
                          0
                      ) {
                        department[
                          "savedGradesToEsis-2"
                        ].successfulLessonCounter = 0;
                        department[
                          "savedGradesToEsis-2"
                        ].totalLessonCounter = 1;
                      }
                    });

                  var found = school.departments.find(
                    (dep) => dep.index == department.index
                  );
                  if (found) {
                    school.departments[school.departments.indexOf(found)] =
                      department;
                  }
                  this.$forceUpdate();
                });
              });
            this.schools.push(school);
          }
        });
      });
  },
  methods: {
    printLog() {
      fb.db
        .collection("schoolGradeErrorLogs2")
        .where("failReason", "==", "missing plans")
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            let dataa = doc.data();
            dataa.school.ref.get().then((school) => {
              fb.db
                .collection(dataa.lessonRef)
                .get()
                .then((doc) => {
                  var lesson = doc.data();
                  console.log(
                    school.data().id,
                    school.data().id.name,
                    lesson.courseInfo.COURSE_ID,
                    lesson.courseInfo.COURSE_NAME
                  );
                });
            });
          });
        });
    },
    print22(lesson) {
      console.log(lesson.ref.path, lesson);
    },
    async allProgramPlans() {
      var schools = this.schools.slice(40, this.schools.length);
      console.log(schools);
      for (let i = 0; i < schools.length; i += 20) {
        const chunk = schools.slice(i, i + 20);
        // for (var school of chunk) {
        chunk.forEach(async (school) => {
          if (school._esisUserName && school._esisPword) {
            await axios
              .post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
                {
                  username: school._esisUserName,
                  password: school._esisPword,
                }
              )
              .then((res) => {
                console.log("token ok");
                this._callAPI(school, res.data.token);
                // for (var department of school.departments) {
                //   if (department.programs) {
                //     for (var program of department.programs) {
                //       this._loadStudentsOfProg(program, res.data.token, school);
                //     }
                //   }
                // }
              });
          }
        });
        console.log("waiting");
        await new Promise((resolve) => setTimeout(resolve, 60000));
      }
    },
    async runAllGrades() {
      for (var depAndId of this.depAndIds) {
        this.allDepartment(depAndId.name, depAndId.id);
        if (depAndId.id == 3) {
          console.log("waiting 10 min");
          await new Promise((resolve) => setTimeout(resolve, 600000));
        }
      }
      console.log("DONEEEE");
    },
    async allProgramStudents() {
      var schools = this.schools.slice(50, 116);
      console.log(schools);
      for (let i = 0; i < schools.length; i += 20) {
        const chunk = schools.slice(i, i + 20);
        chunk.forEach(async (school) => {
          if (school._esisUserName && school._esisPword) {
            await axios
              .post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
                {
                  username: school._esisUserName,
                  password: school._esisPword,
                }
              )
              .then(async (res) => {
                // this._callAPI(school, res.data.token);
                console.log("token ok");
                for (var department of school.departments) {
                  if (department.programs) {
                    for (var program of department.programs) {
                      await this._loadStudentsOfProg(
                        program,
                        res.data.token,
                        school
                      );
                    }
                  }
                }
                console.log(school.name);
              });
          }
        });
        console.log("waiting");
        await new Promise((resolve) => setTimeout(resolve, 60000));
      }
      console.log("done");
    },
    _callAPI(school, ktoken) {
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
          {
            url: "https://hub.esis.edu.mn/svc/api/hub/program/list",
            token: ktoken,
          }
        )
        .then(async (res) => {
          if (res.status === 200) {
            res.data.RESULT.forEach((schoolProgram) => {
              school.ref.update({
                _esis_studyProgramInfo_updatedAt: new Date(),
              });
              school.ref
                .collection("schoolPrograms")
                .doc(schoolProgram.PROGRAM_OF_STUDY_ID.toString())
                .set(schoolProgram, { merge: true })
                .then(() => {
                  axios
                    .post(
                      "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
                      {
                        url:
                          "https://hub.esis.edu.mn/svc/api/hub/program/stage/list/" +
                          schoolProgram.PROGRAM_OF_STUDY_ID,
                        token: ktoken,
                      }
                    )
                    .then(async (stages) => {
                      if (stages.status === 200) {
                        stages.data.RESULT.forEach((schoolStage) => {
                          school.ref
                            .collection("schoolPrograms")
                            .doc(schoolProgram.PROGRAM_OF_STUDY_ID.toString())
                            .collection("stages")
                            .doc(schoolStage.PROGRAM_STAGE_ID.toString())
                            .set(schoolStage, { merge: true })
                            .then(() => {
                              console.log("stages read");
                              axios
                                .post(
                                  "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
                                  {
                                    url:
                                      "https://hub.esis.edu.mn/svc/api/hub/program/stage/plan/list/" +
                                      schoolProgram.PROGRAM_OF_STUDY_ID +
                                      "/" +
                                      schoolStage.PROGRAM_STAGE_ID.toString(),
                                    token: ktoken,
                                  }
                                )
                                .then(async (plans) => {
                                  if (plans.status === 200) {
                                    plans.data.RESULT.forEach((coursePlan) => {
                                      school.ref
                                        .collection("schoolPrograms")
                                        .doc(
                                          schoolProgram.PROGRAM_OF_STUDY_ID.toString()
                                        )
                                        .collection("stages")
                                        .doc(
                                          schoolStage.PROGRAM_STAGE_ID.toString()
                                        )
                                        .collection("plans")
                                        .doc(
                                          coursePlan.PROGRAM_PLAN_ID.toString()
                                        )
                                        .set(coursePlan, { merge: true })
                                        .then(() => {
                                          //reading courses/lessons according the plan
                                          axios
                                            .post(
                                              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
                                              {
                                                url:
                                                  "https://hub.esis.edu.mn/svc/api/hub/program/stage/plan/course/list/" +
                                                  schoolProgram.PROGRAM_OF_STUDY_ID +
                                                  "/" +
                                                  schoolStage.PROGRAM_STAGE_ID.toString() +
                                                  "/" +
                                                  coursePlan.PROGRAM_PLAN_ID,
                                                token: ktoken,
                                              }
                                            )
                                            .then(async (courses) => {
                                              if (courses.status === 200) {
                                                courses.data.RESULT.forEach(
                                                  (course) => {
                                                    course.createdAt =
                                                      new Date();
                                                    school.ref
                                                      .collection(
                                                        "schoolPrograms"
                                                      )
                                                      .doc(
                                                        schoolProgram.PROGRAM_OF_STUDY_ID.toString()
                                                      )
                                                      .collection("stages")
                                                      .doc(
                                                        schoolStage.PROGRAM_STAGE_ID.toString()
                                                      )
                                                      .collection("plans")
                                                      .doc(
                                                        coursePlan.PROGRAM_PLAN_ID.toString()
                                                      )
                                                      .collection("courses")
                                                      .doc(
                                                        course.COURSE_ID.toString()
                                                      )
                                                      .set(course, {
                                                        merge: true,
                                                      });
                                                  }
                                                );
                                                console.log(
                                                  "courses added",
                                                  school.name
                                                );
                                              }
                                            });
                                        });
                                    });
                                  }
                                });
                            });
                        });
                      }
                    });
                });
            });
          }
        });
    },
    async _loadStudentsOfProg(prog, ktoken, school) {
      await axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
          {
            url:
              "https://hub.esis.edu.mn/svc/api/hub/group/student/list/" +
              prog.STUDENT_GROUP_ID,
            token: ktoken,
          }
        )
        .then(async (res) => {
          console.log(res.status);
          if (res.status === 200) {
            var batch = fb.db.batch();
            res.data.RESULT.forEach((item) => {
              item["classGroup-" + school.currentYear] = prog.ref;
              item["classGroupName-" + school.currentYear] =
                item.STUDENT_GROUP_NAME ? item.STUDENT_GROUP_NAME : null;
              item.role = "student";
              item.roleName = "Сурагч";
              item.readfrom_esis = true;
              item.email = item.EMAIL ? item.EMAIL : null;
              item.readfrom_esis = true;
              item.departmentIndex = parseInt(item.ACADEMIC_LEVEL);
              item.classGroupName = item.STUDENT_GROUP_NAME;
              item.deleted = false;
              item.created = new Date();
              item.lastName = item.LAST_NAME ? item.LAST_NAME : null;
              item.firstName = item.FIRST_NAME ? item.FIRST_NAME : null;

              batch.set(
                school.ref
                  .collection("students-" + school.currentYear)
                  .doc(item.PERSON_ID.toString()),
                item,
                { merge: true }
              );
            });

            batch.commit().then(() => {
              if (res.data.RESULT.length > 0) {
                console.log("updating... ", prog.ref.path);
              }
            });
          }
        });
    },
    allDepartment(depIndex, selectedHalfYearId) {
      if (!this.analysisMode) {
        if (selectedHalfYearId) {
          var gradeIndex = this.depAndIds.find(
            (dep) => dep.name == depIndex && dep.id == selectedHalfYearId
          );
          for (let school of this.schools) {
            this.saveDepartmentScore(
              school,
              school.departments[gradeIndex.name - 1],
              gradeIndex.id,
              gradeIndex
            );
          }
        } else {
          var gradeIndexs = this.depAndIds.filter(
            (dep) => dep.name == depIndex
          );
          for (let school of this.schools) {
            for (gradeIndex of gradeIndexs) {
              this.saveDepartmentScore(
                school,
                school.departments[gradeIndex.name - 1],
                gradeIndex.id,
                gradeIndex
              );
            }
          }
        }
      }
    },
    saveSchool(school) {
      if (!this.analysisMode) {
        for (var gradeIndex of this.depAndIds) {
          this.saveDepartmentScore(
            school,
            school.departments[gradeIndex.name - 1],
            gradeIndex.id,
            gradeIndex
          );
        }
      }
    },
    getDataAsString(data) {
      return (
        (
          (data.successfulLessonCounter / data.totalLessonCounter) *
          100
        ).toFixed(0) + "%"
      );
      // return data.successfulLessonCounter + "/" + data.totalLessonCounter;
    },
    showError() {
      fb.db
        .collection("schoolGradeErrorLogs")
        .where("failReason", "==", "missing plans")
        .get()
        .then((docs) => {
          console.log(docs.size);
        });
    },
    async testingg() {
      const headers = {
        "Content-Type": "application/json",
      };
      var requestBody = {
        GRADING_SCHEME_ID: 14,
        school: this.schools[10],
        token: false,
      };
      await axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/gradeIdRetriever",
          requestBody,
          headers
        )
        .then((response) => {
          console.log(response);
        });
    },
    isThisSemesterLesson(lesson, selectedHalfYearId) {
      if (lesson.startEnd) {
        if (selectedHalfYearId > 1) {
          if (
            (lesson.startEnd["semester-3"] &&
              lesson.startEnd["semester-3"].available) ||
            (lesson.startEnd["semester-4"] &&
              lesson.startEnd["semester-4"].available)
          ) {
            lesson.isNotThisSemester = false;
          } else {
            lesson.isNotThisSemester = true;
          }
        } else {
          if (
            (lesson.startEnd["semester-1"] &&
              lesson.startEnd["semester-1"].available) ||
            (lesson.startEnd["semester-2"] &&
              lesson.startEnd["semester-2"].available)
          ) {
            lesson.isNotThisSemester = false;
          } else {
            lesson.isNotThisSemester = true;
          }
        }
      } else {
        lesson.isNotThisSemester = false;
      }
    },
    async saveDepartmentScore(
      school,
      department,
      selectedHalfYearId,
      gradeIndex
    ) {
      if (
        school.id == "Dd2s7L743YkeyIV6mAom" ||
        school.id == "S6uLPIX9NNhE61CMdNMj" ||
        school.id == "JR9yXmuAv8nRcBJtPrAE"
      ) {
        return;
      }
      if (selectedHalfYearId != 3) {
        if (this.analysisMode) {
          this.programLessons = [];
          for (let program of department.programs) {
            await school.ref
              .collection("lessons-" + school.currentYear)
              .where("classGroupRefs", "array-contains", program.ref)
              .get()
              .then((docs) => {
                docs.forEach(async (doc) => {
                  let lesson = doc.data();
                  lesson.ref = doc.ref;
                  lesson.id = doc.id;
                  lesson.program = program;
                  this.isThisSemesterLesson(lesson, selectedHalfYearId);
                  if (!lesson.isNotThisSemester) {
                    var byTeachers = [];
                    lesson.byTeachers.forEach((teacher) => {
                      if (
                        teacher &&
                        teacher.teacherId &&
                        byTeachers.find(
                          (tt) => tt.teacherId == teacher.teacherId
                        ) == undefined
                      ) {
                        byTeachers.push(teacher);
                      }
                    });
                    lesson.byTeachers = byTeachers;

                    lesson.halfYearId = selectedHalfYearId;
                    if (
                      lesson.byTeachers &&
                      lesson.byTeachers.length > 0 &&
                      !lesson.courseInfo.COURSE_NAME.includes("БЗЦ")
                    ) {
                      if (selectedHalfYearId == 3) {
                        // if (!lesson["finalGradeSavedToEsis"])
                        this.programLessons.push(lesson);
                      } else {
                        // if (
                        //   !lesson[
                        //     "unelgee-" + selectedHalfYearId + "-SavedToEsis"
                        //   ]
                        // )
                        this.programLessons.push(lesson);
                      }
                    }
                  }
                });
              });
          }
          this.showProgramLessonsDialog = true;
        } else {
          console.log(department.index);
          // axios
          //   .post(
          //     "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
          //     {
          //       username: school._esisUserName,
          //       password: school._esisPword,
          //     }
          //   )
          //   .then((res) => {
          //     console.log(res);
          //     console.log(school._esis_schoolInfo.legalEntityId);
          //   });
          // if (selectedHalfYearId != 3)
          if (
            department.exists
            // &&
            // (!department["savedGradesToEsis-" + selectedHalfYearId] ||
            //   (department["savedGradesToEsis-" + selectedHalfYearId] &&
            //     (department["savedGradesToEsis-" + selectedHalfYearId]
            //       .successfulLessonCounter !=
            //       department["savedGradesToEsis-" + selectedHalfYearId]
            //         .totalLessonCounter ||
            //       isNaN(
            //         department["savedGradesToEsis-" + selectedHalfYearId]
            //           .successfulLessonCounter /
            //           department["savedGradesToEsis-" + selectedHalfYearId]
            //             .totalLessonCounter
            //       ))))
          ) {
            if (!gradeIndex.loading) gradeIndex.loading = {};
            gradeIndex.loading[school.id] = true;
            this.$forceUpdate();
            var halfYearDate = new Date(
              new Date().getFullYear() +
                "-" +
                this.$store.state.calendarButez2[2].months[0].name +
                "-" +
                this.$store.state.calendarButez2[2].months[0].days[0].day
            );
            var halfYear2Date = new Date(
              new Date().getFullYear() +
                "-" +
                this.$store.state.calendarButez2[3].months[
                  this.$store.state.calendarButez2[3].months.length - 1
                ].name +
                "-" +
                this.$store.state.calendarButez2[3].months[
                  this.$store.state.calendarButez2[3].months.length - 1
                ].days[
                  this.$store.state.calendarButez2[3].months[
                    this.$store.state.calendarButez2[3].months.length - 1
                  ].days.length - 1
                ].day
            );
            const headers = {
              "Content-Type": "application/json",
            };

            var success = [];
            var fail = [];

            for (var program of department.programs) {
              try {
                var requestBody = {
                  schoolRef: school.ref.path,
                  programRef: program.ref.path,
                  selectedHalfYear: { yearId: selectedHalfYearId },
                  halfYearDate:
                    selectedHalfYearId == 1 ? halfYearDate : halfYear2Date,
                };
                var funcFailed = false;
                try {
                  var response = await axios
                    .post(
                      "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/programGradesToEsis",
                      requestBody,
                      headers
                    )
                    .then((response) => {
                      return response.data;
                    });
                  console.log(response);
                } catch (e) {
                  console.log(e);
                  funcFailed = true;
                }

                if (
                  !funcFailed &&
                  response.graduated == true &&
                  !program._esis_graduated
                ) {
                  program.ref
                    .update({
                      _esis_graduated: true,
                    })
                    .then(() => {
                      program._esis_graduated = true;
                      this.$forceUpdate();
                    });
                }

                if (!funcFailed && !response.noStudents) {
                  console.log(response, 1);
                  var fails = [];
                  response.failedLessons.forEach((lsn) => {
                    if (
                      lsn.reason != "missing scores" &&
                      lsn.reason != "missing score 2"
                    )
                      fails.push(lsn);
                  });
                  console.log(fails.length > 0 ? fails : "good");
                  if (selectedHalfYearId == 3) {
                    // fails.forEach((fail) => {
                    //   if (fail.ref) {
                    //     fb.db.collection("schoolGradeErrorLogs3").add({
                    //       createdAt: new Date(),
                    //       school: {
                    //         name: school.name,
                    //         ref: school.ref,
                    //       },
                    //       department: {
                    //         name: department.name,
                    //         ref: department.ref,
                    //       },
                    //       lessonRef: fail.ref,
                    //       programRef: fail.programRef,
                    //       failReason: fail.reason,
                    //     });
                    //   } else {
                    //     fb.db.collection("schoolGradeErrorLogs3").add({
                    //       createdAt: new Date(),
                    //       school: {
                    //         name: school.name,
                    //         ref: school.ref,
                    //       },
                    //       department: {
                    //         name: department.name,
                    //         ref: department.ref,
                    //       },
                    //       programRef: fail.programRef,
                    //       failReason: fail.reason,
                    //       failCode: 1,
                    //     });
                    //   }
                    // });
                    // if (response.successfulLessons.length > 0 && response.token)
                    //   await axios
                    //     .post(
                    //       "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/savingFinalGradesToEsis",
                    //       {
                    //         token: response.token,
                    //         successfulLessons: response.successfulLessons,
                    //         failedLessons: response.failedLessons,
                    //       },
                    //       headers
                    //     )
                    //     .then((response) => {
                    //       console.log(response.data.fail, 3);
                    //     });
                  } else {
                    if (response.successfulLessons.length > 0 && response.token)
                      await axios
                        .post(
                          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/savingUnelgeeGradesToEsis",
                          {
                            token: response.token,
                            successfulLessons: response.successfulLessons,
                            failedLessons: response.failedLessons,
                          },
                          headers
                        )
                        .then((response) => {
                          success.push(response.data.success);
                          success.push(response.data.alreadyDone);
                          fail.push(response.data.fail);
                          fail.push(response.data.failedLessons);
                          console.log(response, selectedHalfYearId);
                        });
                  }
                }
              } catch (e) {
                fb.db.collection("schoolGradeErrorLogs4").add({
                  createdAt: new Date(),
                  school: {
                    name: school.name,
                    ref: school.ref,
                  },
                  department: {
                    name: department.name,
                    ref: department.ref,
                  },
                  failCode: 500,
                  failReason:
                    "function fail (huselted tohirson utga baihgui check google logs)",
                });
                console.log(e);
              }
            }

            let tmp2 = {};
            tmp2["savedGradesToEsis-" + selectedHalfYearId] = {
              successfulLessonCounter: success.length,
              totalLessonCounter: fail.length,
            };
            console.log(tmp2);
            program.ref.update(tmp2);
            // try {
            //   var response = await axios
            //     .post(
            //       "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/schoolGradesToEsis",
            //       requestBody,
            //       headers
            //     )
            //     .then((response) => {
            //       return response.data;
            //     });
            //   console.log(response, 1);
            //   var fails = [];
            //   response.failedLessons.forEach((lsn) => {
            //     if (
            //       lsn.reason != "missing scores" &&
            //       lsn.reason != "missing score 2"
            //     )
            //       fails.push(lsn);
            //   });
            //   console.log(fails.length > 0 ? fails : "good");
            //   if (selectedHalfYearId == 3) {
            //     fails.forEach((fail) => {
            //       if (fail.ref) {
            //         fb.db.collection("schoolGradeErrorLogs3").add({
            //           createdAt: new Date(),
            //           school: {
            //             name: school.name,
            //             ref: school.ref,
            //           },
            //           department: {
            //             name: department.name,
            //             ref: department.ref,
            //           },
            //           lessonRef: fail.ref,
            //           programRef: fail.programRef,
            //           failReason: fail.reason,
            //         });
            //       } else {
            //         fb.db.collection("schoolGradeErrorLogs3").add({
            //           createdAt: new Date(),
            //           school: {
            //             name: school.name,
            //             ref: school.ref,
            //           },
            //           department: {
            //             name: department.name,
            //             ref: department.ref,
            //           },
            //           programRef: fail.programRef,
            //           failReason: fail.reason,
            //           failCode: 1,
            //         });
            //       }
            //     });
            //     if (response.successfulLessons.length > 0 && response.token)
            //       await axios
            //         .post(
            //           "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/savingFinalGradesToEsis",
            //           {
            //             token: response.token,
            //             successfulLessons: response.successfulLessons,
            //             failedLessons: response.failedLessons,
            //           },
            //           headers
            //         )
            //         .then((response) => {
            //           console.log(response.data.fail, 3);
            //         });
            //   } else {
            //     // response.successfulLessons.forEach((lsn) => {
            //     //   delete lsn["unelgee-1-SavedToEsis"];
            //     // });
            //     if (response.successfulLessons.length > 0 && response.token)
            //       await axios
            //         .post(
            //           "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/savingUnelgeeGradesToEsis",
            //           {
            //             token: response.token,
            //             successfulLessons: response.successfulLessons,
            //             failedLessons: response.failedLessons,
            //           },
            //           headers
            //         )
            //         .then((response) => {
            //           console.log(response, selectedHalfYearId);
            //         });
            //   }
            // } catch (e) {
            //   fb.db.collection("schoolGradeErrorLogs3").add({
            //     createdAt: new Date(),
            //     school: {
            //       name: school.name,
            //       ref: school.ref,
            //     },
            //     department: {
            //       name: department.name,
            //       ref: department.ref,
            //     },
            //     failCode: 500,
            //     failReason:
            //       "function fail (huselted tohirson utga baihgui check google logs)",
            //   });
            //   console.log(e);
            // }

            gradeIndex.loading[school.id] = false;
            this.$forceUpdate();
          }
        }
      }
    },
  },
};
</script>
<style>
.bborder {
  border-left: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
}
.bborder td {
  border-right: 1px solid #bbb !important;
  padding: 0 !important;
}
.bborder th {
  border: 1px solid #bbb;
  border-left: 0px;
  padding: 0 !important;
}
[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  right: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: rgb(0, 60, 255);
  color: white;
  opacity: 0;
  z-index: 2;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}
</style>
